import { Outlet, useNavigation, useLoaderData, useSubmit } from "react-router-dom";
import MainNavigation from "../components/MainNavigation";
import Footer from "../components/Footer";
import { useEffect } from "react";
import { getTokenDuration } from "../util/auth";
import { Container, Grid } from "@mui/material";
import Welcome from "../components/Welcome";

function RootLayout() {
    const navigation = useNavigation();
    const token = useLoaderData();
    const submit = useSubmit();

    useEffect(() => {
        if (!token) {
            return;
        }

        if (token === 'EXPIRED') {
            submit(null, { action: '/logout', method: 'post' });
            return;
        }

        const tokenDuration = getTokenDuration();
        setTimeout(() => {
            submit(null, { action: '/logout', method: 'post' });
        }, tokenDuration)
    }, [token, submit]);

    return <>
        <Container maxWidth="lg">
            <MainNavigation />
            <main>
                <Grid container sx={{ padding: "20px" }} sc={{ mt: 3 }}>
                    <Grid container item xs={12} lg={3} sx={{ ml: { xs: 4, lg: 0 }}}>
                        <Welcome />
                    </Grid>
                    <Grid container item xs={12} lg={9} sx={{ textAlign: { xs: 'center', lg: 'left' }, mt: { xs: 1, lg: 0 }}} rowSpacing={0}>
                        {navigation.state === 'loading' && <p>Loading...</p>}
                        <Outlet />
                    </Grid>
                </Grid>
            </main>
            <Footer />
        </Container>
    </>
}

export default RootLayout;
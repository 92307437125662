import { useSubmit, useRouteLoaderData } from 'react-router-dom';
import { Grid, Typography, Button } from '@mui/material';
import { getFormattedDate } from '../util/date';

function BlogPost({ post }) {
    const submit = useSubmit();
    const token = useRouteLoaderData('root');

    function startDeleteHandler() {
        const proceed = window.confirm('Are you sure?');

        if (proceed) {
            submit(null, { method: 'DELETE' })
        }
    }

    return (
        <Grid item>
            <Typography variant="h2" gutterBottom>
                {post.title}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                {getFormattedDate(post.date)}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {post.content}            
            </Typography>
            {token && <menu>
                <Button underline="none" component="button" color="inherit" href={`/blog/${post.id}/edit`}>Edit</Button>
                <Button underline="none" component="button" color="inherit" onClick={startDeleteHandler}>Delete</Button>
            </menu>}
            <Button underline="none" component="button" href="/blog">Back to all Blog posts...</Button>
        </Grid>
    );
}

export default BlogPost;
import { useNavigate, Form, useNavigation, useActionData, redirect, json } from 'react-router-dom';
import { getAuthToken } from '../util/auth';

//TODO add styling at some point
function BlogPostForm({ method, blogpost }) {
    const data = useActionData();
    const navigate = useNavigate();
    const navigation = useNavigation();

    const isSubmitting = navigation.state === "submitting";

    function cancelHandler() {
        navigate('..');
    }

    return (
        <Form method={method}>
            {data && data.errors && <ul>
                {Object.values(data.errors).map(err => <li key={err}>{err}</li>)}
            </ul>}
            <p>
                <label htmlFor="title">Title</label>
                <input id="title" type="text" name="title" required defaultValue={blogpost ? blogpost.title : ''} />
            </p>
            <p>
                <label htmlFor="content">Content</label>
                <textarea id="content" name="content" rows="5" required defaultValue={blogpost ? blogpost.content : ''} />
            </p>
            <div>
                <button type="button" onClick={cancelHandler} disabled={isSubmitting}>
                    Cancel
                </button>
                <button disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Save'}
                </button>
            </div>
        </Form>
    );
}

export default BlogPostForm;

export async function action({ request, params }) {
    const method = request.method;
    const data = await request.formData();

    const currentDate = new Date();
  
    const blogPostData = {
      title: data.get('title'),
      content: data.get('content'),
      date: currentDate
    };
  
    //TODO these need to be updated to build the url differently based on env
    //+ ":" + process.env.REACT_APP_BACK_PORT
    let url = process.env.REACT_APP_BACK_URL + '/blog';
  
    if (method === 'PUT') {
      console.log("updating blog post...")
      const blogPostId = params.id;
      url = url + '/' + blogPostId;
    }
  
    const token = getAuthToken();
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(blogPostData),
    });
  
    if (response.status === 422) {
      return response;
    }
  
    if (!response.ok) {
      throw json({ message: 'Could not save blog post!' }, { status: 500 });
    }
  
    return redirect('/blog');
  } 
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Divider } from '@mui/material';

const theme = createTheme();

function Footer() {
    return (
        <ThemeProvider theme={theme}>
            <Divider />
            <Box component="footer" sx={{ bgcolor: 'background.paper', py: 2, px: 2, mt: 'auto' }}>
                <Container maxWidth="lg">
                    <Typography variant="h6" align="center" gutterBottom>
                        Sean Crim
                    </Typography>
                    <Typography variant="body2" color="text.secondary" align="center">
                        {new Date().getFullYear()}
                    </Typography>
                </Container>
            </Box>
        </ThemeProvider>

    )
}

export default Footer;
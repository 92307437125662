import { Link, useRouteLoaderData } from "react-router-dom";
import Grid from "@mui/material/Grid";
import CardActionArea from "@mui/material/CardActionArea";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { getFormattedDate } from "../util/date";

//TODO get a preview of posts.. cut the length somehow for the list bc dont wanna show entire post
function BlogPostList({ posts }) {
    const token = useRouteLoaderData('root');

    return (
        <>
            {posts.map((post) => (
                <Grid item xs={9} sx={{ p: '5px' }} key={post.id}>
                    <CardActionArea component="a" href={`/blog/${post.id}`}>
                        <Card sx={{ display: 'flex'}}>
                            <CardContent sx={{ flex: 1 }}>
                                <Typography component="h2" variant="h5">
                                    {post.title}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary">
                                    {getFormattedDate(post.date)}
                                </Typography>
                                <Typography variant="subtitle1" color="primary">
                                    Continue reading...
                                </Typography>
                            </CardContent>
                        </Card>
                    </CardActionArea>
                </Grid>
            ))}
            {token && <Link to="new">New Blog Post</Link>}
        </>
    );
}

export default BlogPostList;
import PortfolioEntry from "./PortfolioEntry";

function PortfolioList() {

    //TODO refactor to backend
    const entries = [
        {
            id: 1,
            link: 'https://jeff-zine.com',
            title: 'Jeff Zine',
            thumb: 'https://storage.googleapis.com/zines-jeff/cover/jeff_zine_vol_1_cover.png',
            description: 'This site was created to host a punk music zine that I had created called Jeff Zine. I decided it would be good practice to make my own site to house this rather than purchase some sort of precreated content management site like Bigcartel or Blogspot.'
        }
    ]

    return (
        <>
            {entries.map((entry) => (
                <PortfolioEntry entry={entry} />
            ))}
        </>
    )
}

export default PortfolioList;
import Design from "../components/Design";
import { Await, defer, json, redirect, useRouteLoaderData } from "react-router-dom";
import { Suspense } from "react";
import { getAuthToken } from '../util/auth';

function DesignsDetailPage() {
    const { design } = useRouteLoaderData('design-detail');

    return <Suspense fallback={<p style={{ textAlign: 'center' }}>Loading...</p>}>
        <Await resolve={design}>
            {loadedDesign => <Design design={loadedDesign} />}
        </Await>
    </Suspense>;
}

export default DesignsDetailPage;

async function loadDesign(id) {
    //TODO these need to be updated to build the url differently based on env
    //+ ":" + process.env.REACT_APP_BACK_PORT
    const response = await fetch(process.env.REACT_APP_BACK_URL + "/designs/" + id);

    if (!response.ok) {
        throw json({ message: "could not find design." }, { status: 500 });
    } else {
        const resData = await response.json();
        return resData;
    }
}

export async function loader({ params }) {
    const id = params.id;

    return defer({
        design: await loadDesign(id)
    });
}

export async function action({ request, params }) {
    console.log(params);
    const id = params.id;

    const token = getAuthToken();
    //TODO these need to be updated to build the url differently based on env
    //+ ":" + process.env.REACT_APP_BACK_PORT
    const response = await fetch(process.env.REACT_APP_BACK_URL + "/designs/" + id, {
        method: request.method,
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });

    if (!response.ok) {
        throw json({ message: 'could not delete selected design.' }, { status: 500 });
    }

    return redirect('/designs');
}
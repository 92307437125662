import DesignList from "../components/DesignList";
import { Await, defer, json, useLoaderData } from "react-router-dom";
import { Suspense } from "react";

function DesignsPage() {
    const { designs } = useLoaderData();
    //TODO designs should be auto filtered based on filter criteria

    return (
        <>
            <Suspense fallback={<p style={{ textAlign: 'center' }}>Loading...</p>}>
                <Await resolve={designs}>
                    {loadedDesigns => <DesignList designs={loadedDesigns} />}
                </Await>
            </Suspense>
        </>
    );
}

export default DesignsPage;

async function loadDesigns() {
    //TODO these need to be updated to build the url differently based on env
    //+ ":" + process.env.REACT_APP_BACK_PORT
    const response = await fetch(process.env.REACT_APP_BACK_URL + "/designs");

    if (!response.ok) {
        return json({ message: "could not fetch designs." }, { status: 500 });
    } else {
        const resData = await response.json();
        return resData;
    }
}

export function loader() {
    return defer({
        designs: loadDesigns()
    });
}
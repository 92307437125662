import { useNavigate, Form, useNavigation, useActionData, redirect, json } from 'react-router-dom';
import { useState } from 'react';
import { getAuthToken } from '../util/auth';

//TODO add styling at some point
function DesignForm({ method, design }) {
    const [selectedFile, setSelectedFile] = useState();
    const [fileIsSelected, setFileIsSelected] = useState(false);
    const data = useActionData();
    const navigate = useNavigate();
    const navigation = useNavigation();

    const isSubmitting = navigation.state === "submitting";

    //TODO refactor
    function fileChangeHandler(event) {
        setSelectedFile(event.target.files[0]);
        setFileIsSelected(true);
    }

    function cancelHandler() {
        navigate('..');
    }

    return (
        <Form method={method}>
            {data && data.errors && <ul>
                {Object.values(data.errors).map(err => <li key={err}>{err}</li>)}
            </ul>}
            <p>
                <label htmlFor="title">Title</label>
                <input id="title" type="text" name="title" required defaultValue={design ? design.title : ''} />
            </p>
            <p>
                <label htmlFor="image">Image</label>
                <input id="image" type="text" name="image" required defaultValue={design ? design.image : ''} />
            </p>
            <p>
                <label htmlFor="imageFile">Image File</label>
                <input id="imageFile" type="file" name="imageFile" onChange={fileChangeHandler} required defaultValue={selectedFile ? selectedFile.name : ''} />
                <input name="imageFileTempStore" value={selectedFile} hidden />
            </p>
            {fileIsSelected ? (
                <div>
                    <p>Filename: {selectedFile.name}</p>
                    <p>Filetype: {selectedFile.type}</p>
                </div>
            ) : (
                <p>Please select a design image.</p>
            )}
            <p>
                <label htmlFor="description">Description</label>
                <textarea id="description" name="description" rows="5" required defaultValue={design ? design.description : ''} />
            </p>
            <div>
                <button type="button" onClick={cancelHandler} disabled={isSubmitting}>
                    Cancel
                </button>
                <button disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Save'}
                </button>
            </div>
        </Form>
    );
}

export default DesignForm;

export async function action({ request, params }) {
    const method = request.method;
    const data = await request.formData();
    //TODO these need to be updated to build the url differently based on env
    //+ ":" + process.env.REACT_APP_BACK_PORT
    let designUrl = process.env.REACT_APP_BACK_URL + '/designs';

    const currentDate = new Date();

    const designData = {
        title: data.get('title'),
        image: data.get('image'),
        date: currentDate,
        description: data.get('description'),
    };

    console.log("method is " + method);

    if (method === 'PUT') {
        const designId = params.id;
        designUrl = designUrl + '/' + designId;
    }

    const token = getAuthToken();
    const response = await fetch(designUrl, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(designData),
    });

    if (response.status === 422) {
        return response;
    }

    if (!response.ok) {
        throw json({ message: 'Could not save design!' }, { status: 500 });
    }

    return redirect('/designs');
} 
import { useRouteLoaderData } from "react-router-dom";
import BlogPostForm from "../components/BlogPostForm";

function EditBlogPostPage() {
    const data = useRouteLoaderData('blog-post-detail');

    console.log(data);

    return <BlogPostForm method='PUT' blogpost={data.blogPost} />;
}

export default EditBlogPostPage;
import { Grid } from '@mui/material';
import { useSubmit, useRouteLoaderData } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { getFormattedDate } from '../util/date';

function Design({ design }) {
    const submit = useSubmit();
    const token = useRouteLoaderData('root');

    function startDeleteHandler() {
            const proceed = window.confirm('Are you sure?');
    
            if (proceed) {
                submit(null, { method: 'DELETE' })
            }
    }

    return (
        <Grid item>
            <Typography variant="h2" gutterBottom>
                {design.title}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                {getFormattedDate(design.date)}
            </Typography>
            <img src={design.image} alt={design.title} style={{height:"500px",width:"500px"}} loading="lazy" />
            <Typography variant="body1" gutterBottom>
                {design.description}            
            </Typography>
            {token && <menu>
                <Button underline="none" component="button" color="inherit" href={`/designs/${design.id}/edit`}>Edit</Button>
                <Button underline="none" component="button" color="inherit" onClick={startDeleteHandler}>Delete</Button>
            </menu>}
            <Button underline="none" component="button" href="/designs">Back to all Designs...</Button>
        </Grid>
    );
}

export default Design;
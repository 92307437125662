import { Outlet } from "react-router-dom";

//TODO implement the design contact form and add  it to the root
//            <DesignContactForm />

function DesignsRootLayout() {
    return (
        <>
            <Outlet />
        </>
    );
}

export default DesignsRootLayout;
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import me_pic from "../assets/img/my_face_pic.jpg"
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

function Welcome() {
    var email = process.env.REACT_APP_MY_EMAIL;
    var my_resume = process.env.REACT_APP_GOOGLE_URL + "/" + process.env.REACT_APP_RESUME_BUCKET + "/" + process.env.REACT_APP_RESUME_FILENAME;

    return (
        <Grid item>
            <Paper elevation={0} sx={{ p: 2, outlineStyle: "solid", outlineWidth: "1px", textAlign: 'center' }}>
                <img
                    src={me_pic}
                    alt="sean crim face"
                    style={{ borderRadius: "5px" }}
                />
                <Typography variant="h4">Sean Crim</Typography>
                <Typography variant="h6">Baltimore, MD</Typography>
                <Typography variant="body1">Software Engineer</Typography>
            </Paper>
            <Paper elevation={0} sx={{ p: 2, outlineStyle: "solid", outlineWidth: "1px", textAlign: 'center' }}>
                <Button underline="none" component="button" color="inherit" href={"mailto:" + email}>Contact Me</Button>
            </Paper>
            <Paper elevation={0} sx={{ p: 2, outlineStyle: "solid", outlineWidth: "1px", textAlign: 'center' }}>
                <Button underline="none" component="button" color="inherit" href={my_resume} download="sean_crim_most_recent_resume" target="_blank">Download My Resume</Button>
            </Paper>
            <Paper elevation={0} sx={{ p: 2, outlineStyle: "solid", outlineWidth: "1px", textAlign: 'center' }}>
                <Button underline="none" component="button" color="inherit" href="https://github.com/seancrim23" target="_blank">My Github</Button>
            </Paper>
        </Grid>
    )
}

export default Welcome;
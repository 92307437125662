import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import RootLayout from './pages/Root';
import ErrorPage from './pages/Error';
import HomePage from './pages/Home';

import './App.css';
import BlogPostsPage, { loader as blogPostsLoader } from './pages/BlogPosts';
import DesignsPage, { loader as designsLoader } from './pages/Designs';
import DesignsRootLayout from './pages/DesignsRoot';
import BlogPostDetailPage, { loader as blogPostDetailLoader, action as deleteBlogPostAction } from './pages/BlogPostDetail';
import DesignsDetailPage, { loader as designDetailLoader, action as deleteDesignAction } from './pages/DesignsDetail';
import PortfolioPage from './pages/Portfolio';
import { action as manipulateBlogPostAction } from './components/BlogPostForm';
import { action as manipulateDesignAction } from './components/DesignForm';
import { action as authorizeAction } from './components/AuthForm';
import AuthPage from './pages/Auth';
import EditBlogPostPage from './pages/EditBlogPost';
import NewBlogPostPage from './pages/NewBlogPost';
import EditDesignPage from './pages/EditDesign';
import NewDesignPage from './pages/NewDesign';
import { action as logoutAction } from './pages/Logout';
import { tokenLoader, checkAuthLoader } from './util/auth';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    id: 'root',
    loader: tokenLoader,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: 'admin',
        element: <AuthPage />,
        action: authorizeAction,
      },
      {
        path: 'logout',
        action: logoutAction
      },
      {
        path: 'blog',
        children: [
          {
            index: true,
            element: <BlogPostsPage />,
            loader: blogPostsLoader
          },
          {
            path: ':id',
            id: 'blog-post-detail',
            loader: blogPostDetailLoader,
            children: [
              {
                index: true,
                element: <BlogPostDetailPage />,
                action: deleteBlogPostAction,
              },
              {
                path: 'edit',
                element: <EditBlogPostPage />,
                action: manipulateBlogPostAction,
                loader: checkAuthLoader,
              }
            ]
          },
          {
            path: 'new',
            element: <NewBlogPostPage />,
            action: manipulateBlogPostAction,
            loader: checkAuthLoader,
          }
        ]
      },
      {
        path: 'designs',
        element: <DesignsRootLayout />,
        children: [
          {
            index: true,
            element: <DesignsPage />,
            loader: designsLoader,
          },
          {
            path: ':id',
            id: 'design-detail',
            loader: designDetailLoader,
            children: [
              {
                index: true,
                element: <DesignsDetailPage />,
                action: deleteDesignAction,
              },
              {
                path: 'edit',
                element: <EditDesignPage />,
                action: manipulateDesignAction,
                loader: checkAuthLoader,
              }
            ]
          },
          {
            path: 'new',
            element: <NewDesignPage />,
            action: manipulateDesignAction,
            loader: checkAuthLoader,
          }
        ]
      },
      {
        path: 'portfolio',
        children: [
          {
            index: true,
            element: <PortfolioPage />
          }
        ]
      }
    ]
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;

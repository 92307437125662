import { useRouteError } from "react-router-dom";
import MainNavigation from "../components/MainNavigation";

import PageContent from "../components/PageContent";

//TODO add more error handling / clean this up
//i think the error parsing needs to get fixed bc it doesnt like JSON parse error.data...
function ErrorPage() {
    const error = useRouteError();

    let title = "An error occurred!";
    let message = "Something went wrong!";

    console.log(error)
    console.log(error.data)
    console.log(error.data.message)
    console.log(error.status)

    if (error.status === 500) {
        console.log("error: " + error.data.message)
        message = error.data.message
        //message = JSON.parse(error.data).message;
    }

    if (error.status === 400) {
        console.log("error: " + error.data.message)
        message = error.data.message
        //message = JSON.parse(error.data).message;
    }

    if (error.status === 404) {
        title = "not found!";
        message = "could not find resource or page";
    }

    return (
        <>
            <MainNavigation />
            <PageContent title={title}>
                <p>{message}</p>
            </PageContent>
        </>
    );
}

export default ErrorPage;
import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';


const theme = createTheme();

function MainNavigation() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <IconButton size="large" color="inherit" aria-label="home" href="/">
          <HomeIcon />
        </IconButton>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          sx={{ flex: 1 }}
        >
          
        </Typography>
        {/*TODO add back when fixed <Button underline="none" component="button" color="inherit" href="/blog">Blog</Button>*/}
        <Button underline="none" component="button" color="inherit" href="/designs">Design</Button>
        <Button underline="none" component="button" color="inherit" href="/portfolio">Portfolio</Button>
      </Toolbar>
    </ThemeProvider>
  );
}

export default MainNavigation;
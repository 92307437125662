import { Grid, CardActionArea, CardContent, Card, Typography } from "@mui/material";

//TODO
//better styling - refactor to another file?
function PortfolioEntry({ entry }) {
    return (
        <Grid item xs={9} sm={9} md={4.5} lg={4.5} xl={4.5}>
            <CardActionArea component="a" target="_blank" href={entry.link}>
                <Card sx={{ display: 'flex' }}>
                    <CardContent sx={{ flex: 1, textAlign: 'center' }}>
                        <Typography variant="h2" gutterBottom>
                            {entry.title}
                        </Typography>
                        <img src={entry.thumb} alt={entry.title} style={{ height: "200px", width: "175px" }} loading="lazy" />
                        <Typography variant="body2" gutterBottom>
                            {entry.description}
                        </Typography>
                    </CardContent>
                </Card>
            </CardActionArea>
        </Grid>
    )
}

export default PortfolioEntry;
import PortfolioList from '../components/PortfolioList';

//TODO
//integrate db calls to load porfolio dynamically
function PortfolioPage() {
    return (
        <PortfolioList />
    )
}

export default PortfolioPage;
import { Suspense } from "react";
import BlogPost from "../components/BlogPost";
import { Await, defer, json, redirect, useRouteLoaderData } from "react-router-dom";
import { getAuthToken } from '../util/auth';

function BlogPostDetailPage() {
    const { blogPost } = useRouteLoaderData('blog-post-detail');

    return <Suspense fallback={<p style={{textAlign: 'center'}}>Loading...</p>}>
            <Await resolve={blogPost}>
                {loadedBlogPost => <BlogPost post={loadedBlogPost} />}
            </Await>
        </Suspense>;
}

export default BlogPostDetailPage;

async function loadBlogPost(id) {
    //TODO these need to be updated to build the url differently based on env
    //+ ":" + process.env.REACT_APP_BACK_PORT
    const response = await fetch(process.env.REACT_APP_BACK_URL + "/blog/" + id);

    if (!response.ok) {
        throw json({ message: "could not find blog post."}, {status: 500});
    } else {
        const resData = await response.json();
        return resData;
    }
}

export async function loader({ params }) {
    const id = params.id;

    return defer({
        blogPost: await loadBlogPost(id)
    });
}

export async function action({ request, params }) {
    console.log(params)
    const id = params.id;

    const token = getAuthToken();
    //TODO these need to be updated to build the url differently based on env
    //+ ":" + process.env.REACT_APP_BACK_PORT
    const response = await fetch(process.env.REACT_APP_BACK_URL + "/blog/" + id, {
        method: request.method,
        headers: {
            'Authorization': 'Bearer ' + token
        },
    });

    if (!response.ok) {
        throw json({ message: 'could not delete selected blog post.' }, { status: 500 });
    }

    return redirect('/blog');
}
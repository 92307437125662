import BlogPostList from "../components/BlogPostList";
import { useLoaderData, json, defer, Await } from "react-router-dom";
import { Suspense } from 'react';

function BlogPostsPage() {
    const { blogPosts } = useLoaderData();

    return <Suspense fallback={<p style={{textAlign: 'center'}}>Loading...</p>}>
        <Await resolve={blogPosts}>
            {(loadedBlogPosts) => <BlogPostList posts={loadedBlogPosts} />} 
        </Await>
    </Suspense>;
}

export default BlogPostsPage;

async function loadBlogPosts() {
    //TODO these need to be updated to build the url differently based on env
    //+ ":" + process.env.REACT_APP_BACK_PORT
    const response = await fetch(process.env.REACT_APP_BACK_URL + "/blog")


    if (!response.ok) {
        return json({ message: "could not fetch events."}, { status: 500 });
    } else {
        const resData = await response.json();
        return resData;
    }
}

export function loader() {
    return defer({
        blogPosts: loadBlogPosts()
    })
}
import { Link, useRouteLoaderData } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { CardMedia, Typography, CardContent, Card, CardActionArea } from "@mui/material";
import { getFormattedDate } from "../util/date";

function DesignList({ designs }) {
    const token = useRouteLoaderData('root');

    return (
        <>
            {designs.map((design) => (
                <Grid item xs={9} sx={{ p: '5px' }} key={design.id}>
                    <CardActionArea component="a" href={`/designs/${design.id}`}>
                        <Card sx={{ display: 'flex' }}>
                            <CardContent sx={{ flex: 1 }}>
                                <Typography component="h2" variant="h5">
                                    {design.title}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary">
                                    {getFormattedDate(design.date)}
                                </Typography>
                            </CardContent>
                            <CardMedia
                                component="img"
                                sx={{ width: 150, p: 2, display: { xs: 'none', sm: 'block' } }}
                                image={design.image}
                                alt={design.title}
                                loading="lazy"
                            />
                        </Card>
                    </CardActionArea>
                </Grid>
            ))}
            {token && <Link to="new">New Design</Link>}
        </>
    );
}

export default DesignList;
import AboutMe from '../components/AboutMe';

function HomePage() {
    return (
        <>
            <AboutMe />
        </>
    );
}

export default HomePage;
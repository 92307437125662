import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

function AboutMe() {
    return (
        <Grid
        item
      >
        <Typography variant="body1" sx={{ fontSize: 18 }} gutterBottom>
        Hello! My name is Sean Crim. I am a Software Engineer based in Baltimore, Maryland. I am looking for a mid-level backend engineer job preferably in Golang. My primary experience is planning and implementing the complete migration of a healthcare reporting system to the cloud. With this system users were able to upload healthcare data reports for various healthcare organizations and generate comprehensive reports of this data to submit to the US government. The work that I did for this system was primarily in Golang on top of Amazon Web Services.        </Typography>
        <Typography variant="body1" sx={{ fontSize: 18 }} gutterBottom>
        In this previous role I learned Golang with no prior experience and am capable of learning another programming language from scratch if needed. I have more recently done backend production level work but am also open to full stack and frontend roles. I am interested in working on a smaller sized product team with other motivated engineers.         </Typography>
        <Typography variant="body1" sx={{ fontSize: 18 }} gutterBottom>
        Around August of last year, I left my previous role when I was offered a job at a new company. One week before my start date the company informed me that they went over their budget and the role was no longer available. I have been actively applying for jobs and interviewing ever since.        </Typography>
        <Typography variant="body1" sx={{ fontSize: 18 }} gutterBottom>
        During this downtime I have been staying busy! At the beginning I wanted to branch out a bit and learn a new framework. I chose Django and spent a few weeks learning the basics. After learning Django, I decided it was time for me to build my own website using Golang along with a Frontend language as a concrete display of my abilities. In order to build this site, I had to refresh my skills in React. Besides these engineering activities, I have also been touring with my various bands and also have spent time learning Photoshop in order to make designs for my own bands and others (feel free to take a look at the "DESIGN" tab for some examples).         </Typography>
        <Typography variant="body1" sx={{ fontSize: 18 }} gutterBottom>
        Thank you for reading! I would be more than happy to answer any questions and / or discuss my previous experience. Feel free to contact me and / or download my resume via the tab on the left.        </Typography>
      </Grid>
    )
}

export default AboutMe;
